import React, { useEffect, useState } from "react";
import {
  Dropzone,
  IMAGE_MIME_TYPE,
  MIME_TYPES,
  type FileRejection,
} from "@mantine/dropzone";
import { IconUpload, IconX } from "@tabler/icons-react";
import { UploadErrorCallback, UppyFile } from "@uppy/core";
import { Loader, Text } from "@mantine/core";
import classNames from "classnames";
import { useRevalidator } from "@remix-run/react";
import { notifications } from "@mantine/notifications";
import { PHOTOS_BUCKET_ID } from "~/config/buckets";
import { MAX_IMAGE_SIZE, MAX_IMAGE_SIZE_ERROR } from "~/config/misc";
import { vars } from "~/config/theme";
import type { OutletContext } from "~/types/shared";
import * as classes from "./ImageUpload.css";

export function ImageUpload({
  label,
  outletContext: { uppy },
  metadata,
  currentImage,
  accept = [MIME_TYPES.jpeg, MIME_TYPES.png],
  className = "",
  onSuccess,
  hasBorder,
}: {
  label?: string;
  outletContext: OutletContext;
  metadata?: Record<string, unknown>;
  currentImage?: React.ReactNode;
  accept?: typeof IMAGE_MIME_TYPE;
  className?: string;
  onSuccess?: (fileName: string) => void;
  hasBorder?: boolean;
}) {
  const { revalidate } = useRevalidator();
  const [currentFileId, setCurrentFileId] = useState<string | null>(null);
  const [loadingId, setLoadingId] = useState<string | null>(null);

  const isLoading = Boolean(currentFileId && currentFileId === loadingId);

  useEffect(
    function addFile() {
      if (!uppy) {
        console.error("Uppy not initialized");
        return;
      }

      const onAddFile = (file: unknown) => {
        if (
          file &&
          typeof file === "object" &&
          "id" in file &&
          "name" in file &&
          typeof file.id === "string" &&
          "meta" in file &&
          typeof file.meta === "object"
        ) {
          uppy.setFileMeta(file.id, {
            bucketName: PHOTOS_BUCKET_ID,
            objectName: file.name,
            contentType: "image/jpeg",
            cacheControl: "3600",
            metadata: JSON.stringify({ ...file.meta }),
          });
        }
      };

      uppy.on("file-added", onAddFile);

      return () => {
        uppy.off("file-added", onAddFile);
      };
    },
    [uppy]
  );

  useEffect(
    function success() {
      if (!uppy) {
        console.error("Uppy not initialized");
        return;
      }

      const handleSuccess = (
        res:
          | UppyFile<Record<string, unknown>, Record<string, unknown>>
          | undefined
      ) => {
        if (typeof res?.meta.objectName === "string") {
          onSuccess?.(res.meta.objectName);
        } else {
          console.error("Invalid file objectName", res);
          notifications.show({
            message: "An error occurred. Please try again.",
            color: vars.colors.red[6],
            icon: <IconX />,
            autoClose: false,
          });
        }

        if (!onSuccess) {
          revalidate();
        }

        setLoadingId(null);
      };

      uppy.on("upload-success", handleSuccess);

      return () => {
        uppy.off("upload-success", handleSuccess);
      };
    },
    [uppy, revalidate, onSuccess]
  );

  useEffect(
    function error() {
      if (!uppy) {
        console.error("Uppy not initialized");
        return;
      }

      const onError: UploadErrorCallback<Record<string, unknown>> = (
        file:
          | UppyFile<Record<string, unknown>, Record<string, unknown>>
          | undefined,
        error: Error
      ) => {
        setLoadingId(null);

        console.error(error);

        notifications.clean();

        notifications.show({
          message: error?.message || "An error occurred. Please try again.",
          color: vars.colors.red[6],
          icon: <IconX />,
          autoClose: false,
        });
      };

      uppy.on("upload-error", onError);

      return () => {
        uppy.off("upload-error", onError);
      };
    },
    [uppy, revalidate]
  );

  useEffect(
    function uploading() {
      if (!uppy) {
        console.error("Uppy not initialized");
        return;
      }

      const onUpload = ({ fileIDs }: { fileIDs: string[] }) => {
        const [id] = fileIDs;
        setLoadingId(id);
      };

      uppy.on("upload", onUpload);

      return () => {
        uppy.off("upload", onUpload);
      };
    },
    [uppy, revalidate]
  );

  const onDrop = async (files: File[]) => {
    if (!uppy) {
      console.error("Uppy not initialized");
      return;
    }

    const [file] = files;

    const { name, type } = file;
    const [filename, extension] = name.split(".");

    const uuid = self?.crypto?.randomUUID() || Date.now();

    const uppyFileId = uppy.addFile({
      type,
      name: `${filename}__${uuid}.${extension}`,
      data: file,
      meta: metadata,
    });

    setCurrentFileId(uppyFileId);
  };

  const onReject = (fileRejections: FileRejection[]) => {
    fileRejections.forEach(({ errors }) => {
      errors.forEach(({ code, message }) => {
        if (code === "file-too-large") {
          notifications.show({
            message: MAX_IMAGE_SIZE_ERROR,
            color: vars.colors.red[6],
            icon: <IconX />,
            autoClose: false,
          });
        } else {
          notifications.show({
            message,
            color: vars.colors.red[6],
            icon: <IconX />,
            autoClose: false,
          });
        }
      });
    });
  };

  return (
    <div className={className}>
      {label ? (
        <Text className={classes.label} component="label">
          {label}
        </Text>
      ) : null}
      <div
        className={classNames({
          [classes.imageUploading]: isLoading,
          [classes.imageUpload]: !isLoading,
        })}
      >
        <Dropzone
          onDrop={onDrop}
          onReject={onReject}
          maxSize={MAX_IMAGE_SIZE}
          accept={accept}
          className={hasBorder ? classes.withBorder : classes.dropzone}
          multiple={false}
          disabled={isLoading}
        />
        {isLoading ? (
          <Loader
            classNames={{
              root: classes.loadingIcon,
            }}
            color="white"
            type="bars"
          />
        ) : (
          <IconUpload className={classes.uploadIcon} />
        )}
        <div className={classes.currentImage}>{currentImage}</div>
      </div>
    </div>
  );
}
